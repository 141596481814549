@font-face {
    font-family: 'Kalameh-Regular';
    src: url('./fonts/Kalameh-Regular.eot');
    src: url('./fonts/Kalameh-Regular.eot?#iefix') format('embedded-opentype'),
         url('./fonts/Kalameh-Regular.woff2') format('woff2'),
         url('./fonts/Kalameh-Regular.woff') format('woff'),
         url('./fonts/Kalameh-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  